import React, { useContext, useEffect, useState } from "react";
import '../../../Assets/style/User-account/dashboard.css'
import Navbar from '../../Navbar'
import { AuthenticatContext, UserAuthContext } from "../../../context/AuthenticatContext";
import { NavLink, Navigate, useParams } from "react-router-dom";

import Leaderboard from "../../Basic-components/dashboard/Leaderboard";
import CourseprogressCard from "./CourseprogressCard";
import CourseCard from "./CourseCard";
import { LinechartData } from "../../../Assets/style/User-account/Dashboard/LinechartData";
function Dashboard() {
  const userData = [
    { name: 'Jan', value: 400 },
    { name: 'Feb', value: 300 },
    { name: 'Mar', value: 200 },
    { name: 'Apr', value: 278 },
    { name: 'May', value: 189 },
    { name: 'Jun', value: 239 },
    { name: 'Jul', value: 349 },
  ];
  useEffect(() => {

    const handleKeyDown = (event) => {
      if (event.ctrlKey) {
        event.preventDefault(); // Prevent default behavior
        // Optionally, you can notify the user that Ctrl key is disabled.
        // alert('Ctrl key is disabled.');
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures that the effect runs only once
  const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext)
  const { userID } = useParams();


  const { authentication_data, update_authentication } = useContext(AuthenticatContext);
  const sidebar = {
    "Leaderboard": "/leaderboard",
    "Progress": "/progress",
    "Contests": "/contests"
  }



  return (
    <div >
      <Navbar sidebar={sidebar} topbar={[]} />
      <div className="dashboard-container">
        <div className="top-banner-title-box">

        </div>
        <div className="content-text">
          <h1 className="title-text-for-dashboard">
            The AI-Based Learning Platform For All

          </h1>

        </div>
        <div className="card-grid-showcase">
          {/* card one */}
          <div className="dashboard-grid-card">
            <h5 className="grid-card-title-text">Java Course Learning</h5>
            <CourseprogressCard />
          </div>
          {/*card two  */}
          <div className="dashboard-grid-card">
            <h2 className="card-course-content-heading">Java Learning</h2>
            <p className="card-course-content-text" > Java remains one of the most popular programming languages worldwide Java is a high-level, object-oriented programming ..<span className="card-course-content-counter">(10/50)</span></p>

            <div className="card-course-continue-btn-section">
              <button className="card-course-continue-btn" ><NavLink to={"#"} className="card-course-continue-link" > Contine </NavLink></button>
            </div>

          </div>
          {/* card three */}
          <div className="dashboard-grid-card">

            <div className="Codemaster-card">
              <div className="Codemaster-card-top">
                <p className="Codemaster-card-title">script.py</p>
                <span className="Codemaster-card-buttons">
                  <button className="codemaster-card-btn Codemaster-card-minimize">
                    <svg viewBox="0 0 10.2 1" y="0px" x="0px"><rect height="1" width="10.2" y="50%" x="0"></rect></svg>
                  </button>
                  <button className="codemaster-card-btn maximize">
                    <svg viewBox="0 0 10 10"><path d="M0,0v10h10V0H0z M9,9H1V1h8V9z"></path></svg>
                  </button>
                  <button className="codemaster-card-btn close">
                    <svg viewBox="0 0 10 10"><polygon points="10.2,0.7 9.5,0 5.1,4.4 0.7,0 0,0.7 4.4,5.1 0,9.5 0.7,10.2 5.1,5.8 9.5,10.2 10.2,9.5 5.8,5.1"></polygon></svg>
                  </button>
                </span>

              </div>
              <div className="Codemaster-card-topfix">
              </div>
              <hr />
              <div className="Codemaster-card-content">
                <p>
                  <code>

                    <b className="c2">print</b><b className="c3">(</b><b className="c4">"Hello World !"</b><b className="c3">)</b><br />
                    <b className="c2">print</b><b className="c3">(</b><b className="c4">"Welcome to CodeMaster"</b><b className="c3">)</b><br />

                    <i className="Codemaster-card-comment">#this is a comment.</i><br />
                    <i className="Codemaster-card-comment">#Try CodeMaster </i>
                  </code>
                </p>
              </div>
              <div className="codemaster-try-now-section">
                <button className="codemaster-try-now-btn"><NavLink className="try-now-btn-link" to={"/compiler"}>Code Now <i className="fa-solid fa-square-arrow-up-right"></i></NavLink> </button>
              </div>
            </div>
          </div>
        
         
         


        </div>
        <br />
        <br />
        

        <div className="progress-graphs-section-1">
          <div className="diplay-panel-for-linegraph-1">
            <h6 className="panel-for-linegraph-1-text">On going learning </h6>
          </div>
           <LinechartData data={userData} />
        </div>

        <br />
        <div className="practcode-courses-avialable-section">
          <CourseCard title="Web Design" cardIcon="Web" cardIconColor="" />
          <CourseCard title="Java Learning" cardIcon=".java" cardIconColor=""/>
          <CourseCard title="C++" cardIcon=".cpp" cardIconColor=""/>
          <CourseCard title="C Programming" cardIcon=".c" cardIconColor="" />
          <CourseCard title="Python" cardIcon=".py" cardIconColor="" />

        </div>

        <div className="practcode-dashboard-showcase">
          <div className="element-1">
            <i className="fa-solid fa-graduation-cap"></i>
            <h1>5996+</h1>
            <span>Learning Students</span>
          </div>
          <div className="element-2">
            <i className="fa-solid fa-code"></i>
            <h1>1000+</h1>
            <span>Coding Contests</span>

          </div>
          <div className="element-3">
            <i className="fa-solid fa-id-card"></i>
            <h1>4+</h1>
            <span>Programming Courses</span>
          </div>
        </div>



      </div>

    </div>

  )

}

export default Dashboard