import React from 'react'
import "./MycourseLanding.css"
import robo1 from "../../../Assets/image/robo1.png"

export const MycourseLanding = () => {
    return (
        <div className='Mycourse-landing-main-div'>
            <div className="landing-bot-container">
                <img className='robot1' src={robo1} alt="" />
            </div>
            <br />
            <div className="button-options-container">

                <button class="bot-landing-otions-button">
                <i className="fa-solid fa-chalkboard-user" style={{color:"green"}}></i> Continue to Course
                </button>

                <button class="bot-landing-otions-button">
                <i className="fa-solid fa-list-check" style={{color:"blue"}}></i> Know About Your Progress
                </button>

                <button class="bot-landing-otions-button">
                <i className="fa-solid fa-robot" style={{color:"#000"}}></i>  Practcode Ai
                </button>

                <button class="bot-landing-otions-button">
                <i className="fa-solid fa-map-pin" style={{color:"red"}}></i> Practcode Playgroud
                </button>

                <button class="bot-landing-otions-button">
                <i className="fa-solid fa-code" style={{color:"black"}} ></i> CodeMaster
                </button>


                <button class="bot-landing-otions-button">
                <i class="fa-solid fa-ranking-star"></i> LeaderBoard
                </button>

                <button class="bot-landing-otions-button">
                   ... Blog post
                </button>
            </div>


        </div>
    )
}
