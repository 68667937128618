import React from "react";
import "../../Assets/style/pages-styles/signup.css";
import { useParams, Navigate, NavLink } from "react-router-dom";
import { useState, useContext } from "react";
import { AuthenticatContext, UserAuthContext } from "../../context/AuthenticatContext";
import Cookies from 'js-cookie';
import endpoints from "../Api/endpoints.json"


export default function Signup() {
  console.log(endpoints.server1)
  // AUTHENTICATION PARAMETERS
  const { loginredirect } = useParams();
  const { authentication_data, update_authentication } = useContext(AuthenticatContext);
  const { UserAuthdata, update_UserAuthData } = useContext(UserAuthContext);
  const { isSignuped, setisSignuped } = useState(false)
  const [username, setInputusername] = useState("");
  const [password, setInputpassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [course, setInputcourse] = useState("IMCA");
  const [year, setInputyear] = useState("1");
  const [section, setInputsection] = useState("A");

  const [responseError, setError] = useState({
    msg: "",
    flag: { color: "green" },
  });
  const [isloading, setloading] = useState(false);

  const setCookie = (name, value, hoursToExpire, userData) => {
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + hoursToExpire * 60 * 60 * 1000
    );
    const cookieValue = `${name}=${encodeURIComponent(
      value
    )};expires=${expirationDate.toUTCString()};path=/`;

    // Store userData in a cookie
    document.cookie = cookieValue;
    Cookies.set('userData', JSON.stringify(userData), { expires: hoursToExpire / 24 });
    // update the Authcontext-states
    update_UserAuthData({ "userId": userData['id'], "username": userData['user'] });
    update_authentication({ authtoken: value, isLoggedIn: true });
  };

  const signup = async (e) => {
    setloading(true);
    e.preventDefault();
    console.log({ "name": username, "email": email, "phone": phone, "course": course, "year": year, "password": password, "section": section });

    const response = await fetch(`${endpoints.server1}/user/auth/newuser`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "name": username, "email": email, "phone": phone, "course": course, "year": year, "password": password, "section": section })
    });
    const data = await response.json();
    setloading(false);
    console.log(response);
    if (response.ok) {
      setCookie('authToken', data.authToken, 2, data);
      setError({ "msg": "Welcome to practcode !", "flag": { color: "green" } });
    } else {
      setError({ "msg": `${data.errors[0]['msg']}`, "flag": { color: "red" } });
    }
  };

  if (authentication_data.isLoggedIn) {
    return <Navigate to={`/contest/preplacementtest2`} />;
  } else {
    return (
      <>
        <div className="signupContainer-for-signup">
          <div className="now-signup">
            <div className="signuptopLogo">
            <div className="logotext-loginpage">Practcode.<span>AI</span></div>
            </div>
            <div id="loginmsg">error</div>
            <div className="signup_mainbox">
              <div className="signup_heading">
                
                <p>Grow with Practcode</p>
              </div>
              <p id="login-status-flag" style={responseError.flag}>
                {responseError.msg}
              </p>

              <form onSubmit={signup}>
                <div>
                  <div className="inputBoxandIcon">
                    <i className="fa-regular fa-user"></i>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      placeholder="Username"
                      required
                      onChange={(event) => {
                        setInputusername(event.target.value);
                      }}
                    />
                  </div>
                </div>

                <div>
                  <div className="inputBoxandIcon">
                    <i className="fa-regular fa-envelope"></i>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      required
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>

                  <div className="inputBoxandIcon">
                    <i className="fa-solid fa-phone"></i>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Phone (10 digits)"
                      pattern="\d{10}"
                      maxLength="10"
                      required
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                    />
                  </div>

                  <div className="inputBoxandIcon">
                    <i className="fa-solid fa-graduation-cap"></i>
                    <select
                      id="course"
                      name="course"
                      required
                      onChange={(event) => {
                        setInputcourse(event.target.value);
                      }}
                    >
                      <option value="" disabled>Select Course</option>
                      <option value="IMCA">IMCA</option>
                      <option value="MCA">MCA</option>
                      <option value="BCA">BCA</option>
                    </select>
                  </div>

                  <div className="inputBoxandIcon">
                    <i className="fa-regular fa-calendar-days"></i>
                    <select
                      id="year"
                      name="year"
                      required
                      onChange={(event) => {
                        setInputyear(event.target.value);
                      }}
                    >
                      <option value="" disabled>Select Year</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>

                  <div className="inputBoxandIcon">
                    <i className="fa-solid fa-square-poll-horizontal"></i>
                    <select
                      id="section"
                      name="section"
                      onChange={(event) => {
                        setInputsection(event.target.value);
                      }}
                    >
                      <option value="" disabled>Select Section</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                    </select>
                  </div>

                  <div className="inputBoxandIcon">
                    <i className="fa-solid fa-lock"></i>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                      required
                      onChange={(event) => {
                        setInputpassword(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="formBtns">
                  <button className="signupBtn" type="submit">
                    {isloading ? (
                      <div className="loader">
                        <div className="inner_loader"></div>
                        <div className="inner_loader"></div>
                        <div className="inner_loader"></div>
                      </div>
                    ) : (
                      "Sign Up"
                    )}
                  </button>
                </div>
                <div className="LoginDirect">
                  <p>
                    Already a Student? <NavLink to="/user/login">Login Now</NavLink>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
