import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom/client'; // Import createRoot from ReactDOM
import "../../Assets/style/pages-styles/mycourses.css";
import { fetchData, Java_topics, getExplanation, getExample } from "../Api/mycourse-fetch-data";
import Internal_quiz from "../Basic-components/Internal_quiz";
import Navbar from "../Navbar";

import leftsideIMG from "../../Assets/image/banner-outside-img/left-side-women.png"
import rightsideIMG from "../../Assets/image/banner-outside-img/right-side-women.jpg"
import DisplayCode from "../Basic-components/Internal-code-component/DisplayCode";
import { SelectionExample } from "../Basic-components/UIVERSE-Components/SelectionExample";
import { Scaningrobot } from "../Basic-components/UIVERSE-Components/Scaningrobot";
import { MycourseLanding } from "../Basic-components/UIVERSE-Components/MycourseLanding";
import { NavLink, Navigate, useParams } from "react-router-dom";
// import centerIMG from "../../Assets/image/banner-outside-img/center-men.png"



export default function MyCourses() {
  // for chatbot-practcode-Ai
  const [isAIbootOpend, set_isAIbootOpend] = useState(false);
  const [SelectedText, set_SelectedText] = useState('');
  const [SelectedSentence, set_SelectedSentence] = useState('');

  const [optionPanelvisible,setoptionpanelVisible] = useState(true);

  const parentDivRef = useRef(null); // Reference to the parent div for inserting the explanation box
  
  const [elements, setElements] = useState([]); // State to store dynamically added elements
  
  useEffect(() => {
   
  }, [elements])

  

  // -------------------------

  const isManualScrolling = useRef(false);
  const typedTextRef = useRef(null); // Reference to your text container
  const stopFlag = useRef(false);
  const insideSingleQuotes = useRef(false);
  const [Internal_quiz_visibility, setvisibility] = useState(false);
  const [typeText_currentIndex, setCurrent_typeIndex] = useState(0);
  const [isloading_data, setloading_data] = useState(true);
  const [isMycourseSidebarVisible, setMycourseSidebarVisible] = useState(true);
  const [displayedText, setDisplayedText] = useState('');
  const [textToType, setTextToType] = useState("");
  const [currentText, setCurrentText] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [islessionVisible, setlessionVisible] = useState({
    first: true,
    second: false,
    third: false,
  });
  const [data, setData] = useState([]);

  const sidebar = {
    "Home": "/",
    "My Courses": "/mycourses",
    "Contests": "#",
    "Dashboard":"/dashboard"
  }


  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const apiData = await fetchData();
        setData(apiData);
        setloading_data(false)
        console.log(apiData)
      } catch (error) {
        // Handle error if needed
        console.log(error)
      }
    };

    fetchDataFromApi();
  }, []);

  useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || window.innerWidth < 768;
    if (isMobile) {
      setMycourseSidebarVisible(false);
    }
  }, []);

  const mycourses_sidebartoggle = () => {
    setMycourseSidebarVisible(!isMycourseSidebarVisible);
  };
  const lession_section_visibility = (key) => {
    setlessionVisible((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const fetchDataFromAPI = async (l, t) => {
    try {
      setCurrentText(t)
      setoptionpanelVisible(false) //hide panle options
      const apiText = await Java_topics(l, t);
      setTextToType(apiText);

      return apiText;
    } catch (error) {
      // Handle error
    }
  };

  const typeTheContent = async (event) => {

    if (event === "resume") {
      await typeText(textToType);
    } else {
      if (!isTyping) {
        setDisplayedText(''); // Clear displayed text before typing new text
        stopFlag.current = false;
        setIsTyping(true);
        const span = event.target.querySelector('span'); // Select the span inside the clicked element
        if (span) {
          span.classList.add('active'); // Add the class to the span
        }
        set_isAIbootOpend(false)
        const fetchedText = await fetchDataFromAPI(event.target.className, event.target.textContent.slice(1));  //.slice(1) to remove . from the first
        await typeText(fetchedText);


        setIsTyping(false);
      }
    }
  };

  const typeText = async (text) => {
    setDisplayedText(text)
    // let tag = false;         // Flag to check if we're inside an HTML tag
    // let currentTag = '';      // Stores characters within an HTML tag
    // let displayContent = '';  // Text to be progressively shown on the screen

    // for (let i = 0; i < text.length && !stopFlag.current; i++) {
    //   const char = text[i];

    //   if (char === '<') {
    //     tag = true;          // Start of a new HTML tag
    //     currentTag += char;   // Begin storing the tag
    //   } else if (char === '>') {
    //     tag = false;          // End of the HTML tag
    //     currentTag += char;   // Complete the tag
    //     displayContent += currentTag; // Append completed tag to the display content
    //     setDisplayedText(displayContent); // Update displayed content
    //     currentTag = ''; // Reset tag for the next potential tag
    //   } else if (tag) {
    //     currentTag += char;   // Accumulate tag characters until it closes
    //   } else {
    //     // Handle special character sequences for newlines and tabs
    //     let textContent = char;

    //     if (text.substr(i, 2) === '\\n') {
    //       // textContent = '<br>';
    //       // i++;                          //just skip this part 
    //     } else if (text.substr(i, 2) === '\\t') {
    //       textContent = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
    //       i++;
    //     } else if (char === '.') {
    //       await new Promise(resolve => setTimeout(resolve, 500)); // Pause after a period
    //     }

    //     displayContent += textContent; // Add regular characters to display content
    //     setDisplayedText(displayContent); // Update displayed content

    //     // Adjust typing delay
    //     await new Promise(resolve => setTimeout(resolve, 0.1));
    //   }
    // }
  };
  // Render the displayed text with HTML parsing enabled
  useEffect(() => {
    if (typedTextRef.current && !isManualScrolling.current) {
      typedTextRef.current.scrollTop = typedTextRef.current.scrollHeight;
    }
  }, [displayedText, isTyping]);

  const handleScroll = () => {
    // Update the manual scrolling state
    isManualScrolling.current = typedTextRef.current.scrollTop + typedTextRef.current.clientHeight < typedTextRef.current.scrollHeight - 10;
  };

  const stopTyping = () => {
    if (insideSingleQuotes.current === false) {
      stopFlag.current = true;
      insideSingleQuotes.current = false;
      setIsTyping(false);
    }
  };

  // practcode-ai-chatbot
  // const handleTextSelection = (event) => {
  //   // get selected text
  //   const selection = window.getSelection().toString();


  //   // check text is selected or not ?
  //   if (selection.trim()) {
  //     // alert(`You selected : "${selection}"`)
  //     set_SelectedText(selection)
  //   }
  // }

  const scrollToBottom = () => {
    const element = document.getElementById("scroller--explainantion");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling
    }
  };

  const handleTextSelection = () => {
    const selection = window.getSelection(); // Get selected text range
    const selectedText = selection.toString().trim(); // Trim whitespace from the selection

    // Proceed only if something is selected
    if (!selectedText) return;

    // Get the parent node and its text content
    const anchorNode = selection.anchorNode; // Node where the selection starts
    const parentDiv = anchorNode?.parentElement;

    if (parentDiv) {
      const fullText = parentDiv.innerText; // Get the complete text of the div
      const sentences = fullText.match(/[^.!?]+[.!?]/g); // Split text into sentences using punctuation

      if (sentences) {
        for (let sentence of sentences) {
          if (sentence.includes(selectedText)) {
            // alert(`You selected "${selectedText}" in the sentence: "${sentence.trim()}"`);
            set_isAIbootOpend(true);
            set_SelectedText(selectedText);
            set_SelectedSentence(sentence.trim());
            return;
          }
        }
      }
    }
  };


  // --------------------------------
  const handleInsertElement = async (heading, mode) => {
    set_isAIbootOpend(false); //hide the msg box
    scrollToBottom() //scroll to bottom
    const newElement = {
      id: elements.length + 1, // Unique ID
      headText: heading,
      bodyText: 'Generating a tailored solution for you', // Placeholder text
      loader: true, // Loader for bodyText
      currentTopic: currentText, // Link the element to the current topic
      example: false, // Placeholder for example data
      showExample: false, // State to manage toggle visibility of example
      exampleLoader: false, // State for example API loader
      examplecode: ''

    };

    // Add the new element with "Loading..." text
    setElements([...elements, newElement]);

    try {
      // Call the API to fetch the explanation
      const fetchedExplanation = await getExplanation(
        SelectedText,
        SelectedSentence,
        currentText,
        mode
      );

      // Update the element's bodyText in state
      setElements((prevElements) =>
        prevElements.map((element) =>
          element.id === newElement.id
            ? { ...element, bodyText: fetchedExplanation['explain'], loader: false }
            : element
        )
      );
    } catch (error) {
      // Handle errors (e.g., display error message)
      setElements((prevElements) =>
        prevElements.map((element) =>
          element.id === newElement.id
            ? { ...element, bodyText: 'Error fetching explanation.' }
            : element
        )
      );
      console.error('Error fetching explanation:', error);
    }
    console.log(elements)
   
  };

  const LoadExample_ = async (id, text, language = "java") => {
   
    try {
      const load___ = async () => {
        const temp__ = await getExample(text, currentText);
        status__("loader", false); // Set the loader state to false
        return temp__;
      };

      const status__ = (mode, status) => {
        if (mode === "loader") {
          setElements((prevElements) =>
            prevElements.map((element) =>
              element.id === id ? { ...element, exampleLoader: status } : element
            )
          );
        } else if (mode === "toggle") {
          setElements((prevElements) =>
            prevElements.map((element) =>
              element.id === id
                ? { ...element, showExample: !element.showExample }
                : element
            )
          );
        }
      };

      const set__ = (data) => {
        if (data !== "") {
          scrollToBottom() //scroll to bottom
          setElements((prevElements) =>
            prevElements.map((element) =>
              element.id === id
                ? {
                  ...element,
                  example: data['example'] ? data['example'] + " i.e  " + data['description'] : data['description'],
                  showExample: true,
                  examplecode: data['code'] !== "" ? data['code'] : "",
                }
                : element
            )
          );
        }
       
      };

      if (!elements || elements.length === 0) {
        console.error("Elements is undefined or empty");
        return;
      }

      elements.forEach((element) => {
        if (element.id === id) {
          if (!element.example) {
            status__("loader", true); // Set loader true to load the data
            load___().then(set__).catch((error) => {
              console.error("Error fetching example:", error);
              status__("loader", false);
            });
          } else {
            status__("toggle", ""); // Toggle visibility
          }
        }
      });
    } catch (error) {
      setElements((prevElements) =>
        prevElements.map((element) =>
          element.id === id
            ? { ...element, example: 'Error loading example.', exampleLoader: false }
            : element
        )
      );
      console.error('Error fetching example:', error);
    }
  
  };


  const handleDeleteElement = (id) => {
    // Filter out the element with the given id
    setElements((prevElements) => prevElements.filter((element) => element.id !== id));
  };
  // ---------------------------------- 
  // --------Topic-Explain-----------

  return (
    <>
      <Navbar sidebar={sidebar} topbar={[]} />
      <div className="Mycourse-main-div">

        <div className={`Mycourses-sidebar ${isMycourseSidebarVisible ? "active-for-responsive" : ""}`} style={!isMycourseSidebarVisible?{width:"20px"}:{width:"400px"}}>
          <div
            className={`mycourse-sidebar-content  ${isMycourseSidebarVisible ? "active" : ""
              }`}
          >
            <h6 className="Top-header-sidebar-mycourse" >Learn - Java</h6>
            <i className="fa-solid fa-spinner fa-spin fa-xl" style={isloading_data ? { display: "block" } : { display: "none" }}></i>
            {Object.entries(data).map(([key, items], index) => (
              <ul
                key={index}
                className={` ${islessionVisible[key] ? "show" : ""}`}
              >
                <h6
                  onClick={() => lession_section_visibility(key)}
                  className="lession-heading"
                >
                  {key}
                  <i
                    className={`fa-solid  ${islessionVisible[key] ? "fa-angle-up" : "fa-angle-down"
                      }`}
                  ></i>
                </h6>
                {items.map((item, itemIndex) => (
                  <li key={itemIndex} className={key} onClick={typeTheContent}>
                    <span className="">&#8226;</span>
                    {item}
                  </li>
                ))}
              </ul>
            ))}

          </div>
          <div className="mycourse-sidebar-toggle-btn">
            <button onClick={mycourses_sidebartoggle} style={isMycourseSidebarVisible ?{}:{background:"#1e2838",color:"white"}}>
              <i
                className={`fa-solid   ${isMycourseSidebarVisible ? "fa-angle-left" : "fa-angle-right"
                  }`}
              ></i>
            </button>
          </div>
        </div>
        {/* mycourse body */}
        <div className="mycourse-body-main-div" >
          <div className="mycourses-banner-section">

            <h1>Practcode Ai</h1>
            {
              optionPanelvisible?<MycourseLanding/>:""
            }
            
           

          </div>
         




          <h4 style={{ borderBottom: "2px solid #7d69ff", width: "max-content", paddingBottom: "6px" }}>{currentText} </h4>
          <div onMouseUp={handleTextSelection}   ref={typedTextRef} style={{ height: 'auto', overflowY: 'auto' }} onScroll={handleScroll}>
            <div className="typedtext"  dangerouslySetInnerHTML={{ __html: displayedText }} />

            <i className="fa-solid fa-ellipsis fa-fade" style={isTyping ? { color: "#7d69ff", fontSize: "25px" } : { display: "none" }}></i>


            <div className="topicexplanation-box" ref={parentDivRef}>
              {elements.map((element) => (
                element.currentTopic === currentText ?
                  <div key={element.id} id={`explaination-of-${currentText.replaceAll(" ","+")}-${element.id}`} className="explanation-inner-box">
                    <div className="inner-box-upper-main">
                      <div className="explanation-inner-head" >
                        <span>
                          <i
                            className="fa-solid fa-reply"
                            style={{ rotate: '180deg', marginRight: '10px' }}
                          ></i>
                        </span>{currentText} :
                        <p className="explanation-inner-text-data">
                          " {element.headText} "
                        </p>

                      </div>
                      <i className="fa-regular fa-circle-xmark close-the-open-query" onClick={() => { handleDeleteElement(element.id) }}></i>
                    </div>
                    <div className="explanation-inner-body">
                      <i
                        className="fa-solid fa-ellipsis fa-fade"
                        style={
                          element.loader
                            ? { color: '#7d69ff', fontSize: '25px' }
                            : { display: 'none' }
                        }
                      ></i>
                      <p className="explanation-body-data">
                        {element.bodyText}
                      </p>
                      <button
                        onClick={() => LoadExample_(element.id, element.bodyText)}
                        className="explanation-example-btn"
                      >
                        {element.exampleLoader
                          ? 'Loading Example...'
                          : element.showExample
                            ? 'Hide Example'
                            : 'Example'}
                      </button>
                      {element.exampleLoader && (
                        <i
                          className="fa-solid fa-spinner fa-spin"
                          style={{ color: '#7d69ff', fontSize: '20px', margin: '5px' }}
                        ></i>
                      )}
                      {element.showExample && element.example && (
                        <>
                        { element.examplecode!==""?
                          <div className="code-example-box">
                            <DisplayCode rawCode={element.examplecode} />
                          </div>
                          :""
                        }

                          <p className="example-data">
                            {element.example}
                          </p>
                        </>
                      )}
                    </div>
                  </div>

                  : ""
              ))}

              {/* <div className="explanation-inner-box">
                <div className="explanation-inner-head">
                  <span>
                    <i className="fa-solid fa-reply" style={{ rotate: "180deg" }}></i>
                  </span>

                  <p className="explanation-inner-text-data" >"explain the text..... " Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam enim, quaerat tempore minima asperiores alias hic placeat dignissimos quibusdam adipisci?</p>
                </div>
                <div className="explanation-inner-body">
                <i className="fa-solid fa-ellipsis fa-fade" style={loadingExplanation ? { color: "#7d69ff", fontSize: "25px" } : { display: "none" }}></i>
                  <p className="explanation-body-data">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus odit ut amet, deleniti est itaque! Corporis debitis modi facere voluptatum itaque sequi voluptatem ipsum nisi.
                  </p>
                </div>
              </div> */}
            </div>
          
         


           

          </div>

          {/* <button onClick={isTyping?stopTyping:resumeTyping} disabled={typeText_currentIndex===0?true:false} className="Stoptypingbtn"><i className={`fa-regular ${isTyping?'fa-circle-stop':"fa-circle-play"}`}></i></button> */}
          <div className="practcode-ai-bot">
            <div className={`bot-room ${isAIbootOpend ? "active" : ""}`} >

              <div className="boot-room-upper-nav">
                Practcode - Ai
              </div>

              <div className="boot-room-body">
                {SelectedText === "" ?
                  <div className="body-placeholder">
                    <Scaningrobot/>
                    <i className="fa-solid fa-arrow-pointer" onClick={() => { isAIbootOpend ? set_isAIbootOpend(false) : set_isAIbootOpend(true) }}></i>
                    <p>Select content ! Practcode-Ai will help to Learn...</p>
                  </div>
                  :
                  <ul style={{ padding: "0px" }}>
                    <p style={{ color: "#7d69ff", fontWeight: "600" }}>Prompts</p>
                    <li onClick={() => handleInsertElement(SelectedText, 'singleword')} style={{ cursor: "pointer", fontSize: "14px", background: "white", padding: "5px 5px", borderRadius: "5px", marginBottom: "5px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>* Not clear "<strong>{SelectedText}</strong>" ?</li>
                    <li onClick={() => handleInsertElement(SelectedSentence, 'line')} style={{ cursor: "pointer", fontSize: "14px", background: "white", padding: "5px 5px", borderRadius: "5px", marginBottom: "5px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>* Confusion in  "<strong>{SelectedSentence}</strong>"</li>
                    <li onClick={() => handleInsertElement(currentText, "topic")} style={{ cursor: "pointer", fontSize: "14px", background: "white", padding: "5px 5px", borderRadius: "5px", marginBottom: "5px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>* stuck in  "<strong>{currentText}</strong>"  !!</li>
                  </ul>
                }





              </div>


            </div>
            <div className="boot-angle-btn-div" style={SelectedText?{display:"block"}:{display:"none"}}>
              <button className="floating-ai-button" onClick={() => { isAIbootOpend ? set_isAIbootOpend(false) : set_isAIbootOpend(true) }}>
                <i className={`fa-solid fa-angle-${isAIbootOpend ? "down" : "up"}`}></i>
              </button>

            </div>

          </div>
          <div id="scroller--explainantion" style={{height:"200px"}}></div>

        </div>

      </div>
    </>
  );
}
