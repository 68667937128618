import React from 'react'
import "./Pageloader.css"
const Pageloader = () => {
    return (
        <div className='loader-main-div-box'>



            <div class="pageloaderloader">
                <div class="loader__circle"></div>
                <div class="loader__circle"></div>
                <div class="loader__circle"></div>
                <div class="loader__circle"></div>
                <div class="loader__circle"></div>
            </div>



        </div>
    )
}

export default Pageloader