import React from 'react'
import "../../../Assets/style/User-account/CourseCard.css"

const CourseCard = ({title,cardIcon,cardIconColor}) => {
  return (
    <div className='course-card-dashboard' >
      <div className="card-upper-body">
        <h2 className='cardIcon'>{cardIcon}</h2>
      </div>
      <div className="card-lower-body">
        <div className="card-course-title">
          {title}
        </div>
        <p className='card-course-description'>
        Lorem ipsum dolor, sit amet consectetur
        </p>
        <button className='card-course-enroll-btn'>Enroll Now</button>
      </div>
    </div>
  )
}

export default CourseCard