import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const LinechartData = ({ data }) => {


  return (
   <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 40,
          left: 10,
          bottom: 5,
        }}
      >
        {/* Show only horizontal grid lines */}
        <CartesianGrid horizontal={true} vertical={false} strokeWidth={0.2} strokeDasharray="3 3" />
        <XAxis dataKey="name"  label={{value:"Month",position:"insideBottom",offset:35}}/>
        <YAxis  label={{value:"Score", angle: -90 ,position:"insideLeft",offset:5}}/>
        <Tooltip />
        {/* <Legend /> */}
        <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  )
}

              