import React from 'react'
import "../../../Assets/style/User-account/CourseprogressCard.css"

const CourseprogressCard = () => {
    return (

        <div className="container-for-courseprogresscard">
            <div className="skill-box">
                <span className="title">Course <p>100%</p></span>
                
                <div className="skill-bar">
                    <span className="skill-per html">
                        <span className="tooltip"></span>
                        
                    </span>
                </div>
            </div>

            <div className="skill-box">
                <span className="title">Quiz <p>80%</p></span>

                <div className="skill-bar">
                    <span className="skill-per scss">
                        <span className="tooltip">80%</span>
                    </span>
                </div>
            </div>
            <div className="skill-box">
                <span className="title">Coding <p>50%</p></span>

                <div className="skill-bar">
                    <span className="skill-per Boostrap">
                        <span className="tooltip">50%</span>
                    </span>
                </div>
            </div>
        </div>

    )
}

export default CourseprogressCard